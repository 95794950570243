<template>
  <div class="row">
    <!-- BEGIN: Column -->
    <div class="col-12">
      <div class="row">
        <div class="col-md col-12 form-group">
          <label for="search-icon-collections" class="d-none">Search</label>
          <input type="text" class="form-control form-control-lg" id="search-icon-collections" :placeholder='`Search ${filterCollectionsByName.length} collections (Press "\\" to focus)`' v-model="search" :disabled="disableSearch" ref="searchPackages">
        </div>
      </div>
    </div>
    <!-- END: Column -->

    <!-- BEGIN: Column -->
    <div class="col-12 table">
      <div class="row table-th align-items-center">
        <div class="h5 mb-0 col"><strong>Name</strong></div>
        <div class="h5 mb-0 col-md-2 text-center"><strong>Version</strong></div>
        <div class="h5 mb-0 col-md-2 text-center"><strong>Status</strong></div>
        <div class="h5 mb-0 col-md-auto d-flex justify-content-center td-action"><strong>Action</strong></div>
      </div>

      <template v-for="(collection) of filterCollectionsByName">
        <div :key="collection.id" :ref="`collection-${collection.id}`">
          <div class="row table-tr align-items-center">
            <div class="col" data-title="Name:">
              {{ collection.name }}
              <template v-if="collection.deprecated">(Deprecated)</template>
            </div>

            <div class="col-md-2 text-center" data-title="Version:">
              {{ typeof awsObject[collection.name] === 'object' ? awsObject[collection.name].version : 'Not available' }}
            </div>

            <div class="col-md-2 text-center" :ref="`status-${collection.id}`" data-title="Status:">
              No status
            </div>

            <div class="col-md-auto d-flex justify-content-center td-action">
              <button class="btn btn-secondary mr-3" @click="updatePackage(collection)" :ref="`update-${collection.id}`">Update</button>
              <button class="btn btn-primary" @click="downloadPackage(collection)" :ref="`download-${collection.id}`">Download</button>
            </div>
          </div>

          <div class="d-flex gap-1 pb-3" style="gap: 4px">
            <!-- Latest URL -->
            <!-- <div class="">
              <button class="default-btn-style badge rounded-pill text-bg-primary" @click="toggleCollection(collection, awsObject, true, true)">
                latest
              </button>
            </div> -->
            <div class="">
              <button class="default-btn-style badge rounded-pill text-bg-primary" @click="toggleCollection(collection, awsObject, true)">
                latest
              </button>
            </div>

            <template v-if="awsObject[collection.name] !== undefined && awsObject[collection.name].version_lists.length">
              <div class="" v-for="subr in awsObject[collection.name].version_lists" :key="subr.version">
                <button class="default-btn-style badge rounded-pill text-bg-primary" @click="toggleCollection(collection, subr)">
                  {{ subr.version }}
                </button>
              </div>
            </template>
          </div>

          <div class="" v-show="collection.listShow">
            <pre class="p-3 rounded">{{ collection.listData }}</pre>
          </div>
        </div>
      </template>
    </div>
    <!-- END: Column -->
  </div>
</template>

<script>
  import Axios from 'axios'
  Axios.defaults.headers.common['Accept'] = 'application/json'
  Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  export default {
    data() {
      return {
        search: '',
        disableSearch: false,
        collections: [
          {
            id: 754,
            name: 'AIOS 301 Redirects',
            slug: 'aios-301-redirects',
            file: 'aios-301-redirects.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 938,
            name: 'AIOS Agents',
            slug: 'aios-agents',
            file: 'aios-agents.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 361,
            name: 'AIOS Communities',
            slug: 'aios-communities',
            file: 'aios-communties.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1443,
            name: 'AIOS Concierge',
            slug: 'aios-concierge',
            file: 'aios-concierge.php',
            branch: 'main',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 778,
            name: 'AIOS Custom IDX Broker',
            slug: 'aios-custom-idx-broker',
            file: 'aios-custom-idxb.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1479,
            name: 'AIOS Custom iHomefinder',
            slug: 'aios-custom-ihomefinder',
            file: 'main.php',
            branch: 'main',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 780,
            name: 'AIOS Filterable Galllery',
            slug: 'aios-filterable-gallery',
            file: 'aios-filterable-gallery.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 751,
            name: 'AIOS Initial Setup',
            slug: 'aios-initial-setup',
            file: 'asis_main.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          // {
          //   id: 749,
          //   name: 'AIOS Agents Module',
          //   slug: 'aios-agents-module-tdp',
          //   file: 'agent_main.php',
          //   branch: 'production',
          //   type: 'plugin',
          //   deprecated: false
          // },
          {
            id: 789,
            name: 'AIOS Home Valuation',
            slug: 'aios-home-valuation',
            file: 'aios-valuation.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 2028,
            name: 'AIOS Listings v3',
            slug: 'aios-listings',
            file: 'aios-listings.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1160,
            name: 'AIOS Optimize',
            slug: 'aios-optimize',
            file: 'index.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 752,
            name: 'AIOS Roadmaps',
            slug: 'aios-roadmaps',
            file: 'roadmaps.php',
            branch: 'production',
            type: 'plugin',
            deprecated: true,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1656,
            name: 'AIOS Roadmaps v2',
            slug: 'aios-roadmaps-v2',
            file: 'aios-roadmaps.php',
            branch: 'main',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1624,
            name: 'AIOS Slider',
            slug: 'aios-slider',
            file: 'main.php',
            branch: 'main',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 753,
            name: 'AIOS S3 Tools',
            slug: 'aios-s3-tools',
            file: 'aios-aws-s3.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1992,
            name: 'AIOS Autopopulation',
            slug: 'aios-autopopulation',
            file: 'aios-autopopulation.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 757,
            name: 'AIOS SEO Tools',
            slug: 'aios-seo-tools',
            file: 'seo-tools-main.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 276,
            name: 'AIOS Testimonials',
            slug: 'aios-testimonials',
            file: 'aios-testimonials.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 2196,
            name: 'AIOS Migration',
            slug: 'aios-migration',
            file: 'aios-migration.php',
            branch: 'main',
            type: 'plugin',
            deprecated: false
          },
          {
            id: 755,
            name: 'AIOS Under Construction V2',
            slug: 'aios-under-construction-v2',
            file: 'aios-under-construction.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 762,
            name: 'Social Media Wall',
            slug: 'aios-dashboard',
            file: 'aios-dashboard.php',
            branch: 'production',
            type: 'plugin',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 756,
            name: 'AIOS All Widgets',
            slug: 'aios-all-widgets',
            file: 'taw_main.php.php',
            branch: 'production',
            type: 'plugin',
            deprecated: true,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 750,
            name: 'AIOS Listings v2',
            slug: 'AIOS_Listings',
            file: 'listing_module.php.php',
            branch: 'production',
            type: 'plugin',
            deprecated: true,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 715,
            name: 'AIOS Starter Theme',
            slug: 'aios-starter-theme',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 716,
            name: 'AIOS Starter Theme (Child)',
            slug: 'aios-starter-theme-child',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1969,
            name: 'AIOS Starter Theme Pack',
            slug: 'aios-starter-theme-pack',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1478,
            name: 'Lead Grid Specter',
            slug: 'lead-grid-specter',
            file: 'style.css',
            branch: 'main',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1488,
            name: 'Lead Grid Iris',
            slug: 'lead-grid-iris',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1979,
            name: 'AgentPro Galaxy',
            slug: 'agentpro-galaxy',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1980,
            name: 'AgentPro Beacon',
            slug: 'agentpro-beacon',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1986,
            name: 'AgentPro Panorama',
            slug: 'agentpro-panorama',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1995,
            name: 'AgentPro Radiance',
            slug: 'agentpro-radiance',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1994,
            name: 'AgentPro Purist',
            slug: 'agentpro-purist',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1997,
            name: 'AgentPro Endeavor',
            slug: 'agentpro-endeavor',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1222,
            name: 'AgentPro North',
            slug: 'agentpro-north',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1261,
            name: 'AgentPro South II',
            slug: 'agentpro-south-ii',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1225,
            name: 'AgentPro West',
            slug: 'agentpro-west',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1423,
            name: 'AgentPro Maven',
            slug: 'agentpro-maven',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1425,
            name: 'AgentPro Vega',
            slug: 'agentpro-vega',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1428,
            name: 'AgentPro Metropolitan',
            slug: 'agentpro-metropolitan',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 726,
            name: 'AgentPro Legacy',
            slug: 'agentpro-legacy',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 724,
            name: 'AgentPro Element',
            slug: 'agentpro-element',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 725,
            name: 'AgentPro Amante II',
            slug: 'agentpro-amante-ii',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 723,
            name: 'AgentPro Iconic',
            slug: 'agentpro-iconic',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 805,
            name: 'AgentPro Vienna',
            slug: 'agentpro-vienna',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 736,
            name: 'AgentPro Havana',
            slug: 'agentpro-havana',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 731,
            name: 'AgentPro Empire',
            slug: 'agentpro-empire',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 737,
            name: 'AgentPro Milan',
            slug: 'agentpro-milan',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 734,
            name: 'AgentPro Florence',
            slug: 'agentpro-florence',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 738,
            name: 'AgentPro Capri',
            slug: 'agentpro-capri',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 733,
            name: 'AgentPro Geneva',
            slug: 'agentpro-geneva',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 729,
            name: 'AgentPro Berlin',
            slug: 'agentpro-berlin',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 735,
            name: 'AgentPro Luxor',
            slug: 'agentpro-luxor',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 732,
            name: 'AgentPro Bordeaux',
            slug: 'agentpro-bordeaux',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 730,
            name: 'AgentPro Sydney',
            slug: 'agentpro-sydney',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 728,
            name: 'AgentPro Montecarlo',
            slug: 'agentpro-montecarlo',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 727,
            name: 'AIX Royale',
            slug: 'aix-royale',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 722,
            name: 'AIX Quantum',
            slug: 'aix-quantum',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 721,
            name: 'AIX Seneca',
            slug: 'aix-seneca',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 720,
            name: 'AIX Hamilton',
            slug: 'aix-hamilton',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 742,
            name: 'AIX Vantage',
            slug: 'aix-vantage',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 741,
            name: 'AIX Venator',
            slug: 'aix-venator',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 740,
            name: 'AIX Icon',
            slug: 'aix-icon',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 739,
            name: 'AIX Skyfall',
            slug: 'aix-skyfall',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
            {
            id: 2037,
            name: 'PropertyPro Mosaic',
            slug: 'propertypro-mosaic',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 2045,
            name: 'PropertyPro Prism',
            slug: 'propertypro-prism',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 745,
            name: 'Propertypro Kolding',
            slug: 'propertypro-kolding',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 744,
            name: 'Propertypro Copenhagen',
            slug: 'propertypro-copenhagen',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 743,
            name: 'Propertypro Randers',
            slug: 'propertypro-randers-single-install',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 748,
            name: 'Communitypro Embassy',
            slug: 'communitypro-embassy',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 747,
            name: 'Communitypro Monaco',
            slug: 'communitypro-monaco-single-install',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 746,
            name: 'Communitypro Montpellier',
            slug: 'communitypro-montpellier-single-install',
            file: 'style.css',
            branch: 'production',
            type: 'theme',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 782,
            name: 'Custom IHF Template',
            slug: 'aios-ihf-template',
            file: 'module.php',
            branch: 'production',
            type: 'module',
            deprecated: true,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 815,
            name: 'Custom Concierge Template',
            slug: 'aios-custom-concierge-template',
            file: null,
            branch: 'production',
            type: 'module',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 816,
            name: 'Custom Agent Template',
            slug: 'aios-custom-agent-template',
            file: null,
            branch: 'production',
            type: 'module',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 817,
            name: 'Custom About Template',
            slug: 'aios-custom-about-template',
            file: null,
            branch: 'production',
            type: 'module',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 819,
            name: 'Custom Contact Template',
            slug: 'aios-custom-contact-template',
            file: null,
            branch: 'production',
            type: 'module',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 820,
            name: 'Custom Blog Template',
            slug: 'aios-blog-template',
            file: null,
            branch: 'production',
            type: 'module',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 821,
            name: 'Post Type: Testimonials',
            slug: 'aios-testimonials-post-type',
            file: null,
            branch: 'production',
            type: 'module',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 822,
            name: 'Post Type: Communities',
            slug: 'aios-communities-post-type',
            file: null,
            branch: 'production',
            type: 'module',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 1067,
            name: 'PHP HTML Parser',
            slug: 'php-html-parser',
            file: null,
            branch: 'master',
            type: 'module',
            deprecated: false,
            listShow: false,
            listData: {},
            openByVersion: '',
          },
          {
            id: 2278,
            name: 'AIOS Custom iHomefinder - Compensation Files',
            slug: 'aios-custom-ihomefinder-compensation-files',
            file: null,
            branch: 'main',
            type: 'module',
            deprecated: false
          },
        ],

        awsObject: []
      }
    },

    async beforeRouteEnter(to, from, next) {
      try {
        const type = to.name !== 'plugins' ? 'themes' : to.name,
          {data} = await Axios.post(`${process.env.VUE_APP_API_URL}/aws/${type}`)
        next(vm => vm.setData(data))
      } catch (e) {
        next({name: 'not-found'})
      }
    },

    async beforeRouteUpdate(to, from, next) {
      try {
        const routeName = to.name.replace('s', ''),
          type = routeName !== 'plugin' ? 'theme' : routeName,
          {data} = await Axios.post(`${process.env.VUE_APP_API_URL}/aws/${type}`)
        this.setData(data)
        next()
      } catch (e) {
        next({name: 'not-found'})
      }
    },

    mounted() {
      // Register an event listener when the Vue component is ready
      window.addEventListener('keypress', this.searcPackagesFocus)

      this.$refs.searchPackages.focus()
    },

    beforeDestroy() {
      // Unregister the event listener before destroying this Vue instance
      window.removeEventListener('keypress', this.searcPackagesFocus)
    },

    computed: {
      filterCollectionsByName() {
        return this.collections.filter(collection => collection.name.toLowerCase().indexOf(this.search.toLowerCase())> -1 && collection.type === this.$route.name.replace('s', ''))
      },
    },

    methods: {
      toggleCollection(collection, data, latest = false, latestUrl = false) {
        if (collection.openByVersion === data.version) {
          // this.modifyCollection(n, collection);
          collection.listShow = false;
          collection.listData = {};
          collection.openByVersion = '';
        } else {
          collection.listShow = true;
          if (latest) {
            const latestDist = latestUrl ? data[collection.name].url.replace(data[collection.name].version, 'latest') : data[collection.name].url

            collection.listData = {
              type: "package",
              package: {
                name: `aios-packages/${collection.slug}`,
                version: data[collection.name].version,
                type: `wordpress-${collection.type}`,
                dist: {
                  type: "zip",
                  url: latestDist
                }
              }
            };
            collection.openByVersion = data[collection.name].version;
          } else {
            collection.listData = {
              type: "package",
              package: {
                name: `aios-packages/${collection.slug}`,
                version: data.version,
                type: `wordpress-${collection.type}`,
                dist: {
                  type: "zip",
                  url: data.url
                }
              }
            };
            collection.openByVersion = data.version;
          }
        }
      },

      async setData(awsObject) {
        this.awsObject = awsObject
      },

      searcPackagesFocus(event) {
        if (event.key === '\\') {
          event.preventDefault()
          this.$refs.searchPackages.focus()
        }
      },

      collectionKeys() {
        return Object.keys(this.collections)
      },

      /**
       * Enable/Disable functions to prevent issues
       *
       * @param id
       * @param isEnabled
       */
      isElementsEnabled(id, isEnabled = true) {
        // Disable search to prevent conflict
        this.disableSearch = isEnabled
        this.$refs[`update-${id}`][0].disabled = isEnabled
        this.$refs[`download-${id}`][0].disabled = isEnabled
      },

      async updatePackage(collection) {
        this.$refs[`status-${collection.id}`][0].textContent = 'Updating...'

        try {
          this.isElementsEnabled(collection.id, true)

          const {data} = await this.$http.post(`${process.env.VUE_APP_API_URL}/package/update`, collection)

          if (this.awsObject[collection.name] !== undefined) {
            this.awsObject[collection.name].version = data.data.version
          }

          this.$notify({
            type: 'success',
            text: `${collection.name}${data.data.version !== undefined && collection.file !== null ? ' updated to version ' + data.data.version : ''}.`,
          })

          // Enable search after update
          this.isElementsEnabled(collection.id, false)

          this.$refs[`status-${collection.id}`][0].textContent = 'Updates Done'
        } catch (e) {
          this.$notify({
            type: 'error',
            text: 'Please try again.',
          })

          // Enable search on error
          this.isElementsEnabled(collection.id, false)
          this.$refs[`status-${collection.id}`][0].textContent = 'Error Uploading'
        }
      },

      async downloadPackage(collection) {
        this.$refs[`status-${collection.id}`][0].textContent = 'Downloading...'

        try {
          // Request for blob data
          // eslint-disable-next-line no-unused-vars
          const {data, status, statusText} = await this.$http({
            method: 'get',
            url: `${process.env.VUE_APP_API_URL}/package/${collection.slug}.zip`,
            responseType: 'blob',
          })

          // Create Object from blob
          const blob = new Blob([data], {type: 'application/zip'})
          const link = document.createElement('a')

          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', `${collection.slug}.zip`)
          link.click()
          URL.revokeObjectURL(link.href)

          this.$notify({
            type: 'success',
            text: `Downloading ${collection['name']}.`,
          })

          this.$refs[`status-${collection.id}`][0].textContent = 'Downloaded'
        } catch (e) {
          this.$notify({
            type: 'error',
            text: 'Please try again.',
          })

          this.$refs[`status-${collection.id}`][0].textContent = 'Error Downloading'
        }
      }
    }
  }
</script>


<style lang="scss" scoped>
  .table {
    div[class*=col] {
      padding-top: $spacing-2;
      padding-bottom: $spacing-2;
      &.td-action {
        width: 220px;
      }
    }

    @include breakpoint(mobile, max) {
      .table-th {
        display: none;
      }
      .table-tr {
        padding-top: $spacing-3;
        padding-bottom: $spacing-3;
        border-top: solid 1px $gray-400;
        div[class*=col] {
          padding-top: 0;
          padding-bottom: 0;
          text-align: center;
          &:not(.td-action)::before {
            content: attr(data-title);
          }
          &.td-action {
            margin-top: $spacing-2;
            width: 100%;
          }
        }
      }
    }
  }
</style>
