<template>
  <div>
    <!-- BEGIN: Form -->
    <validation-observer :ref="`form-edit-${_uid}`" v-slot="{handleSubmit, passed, dirty, valid}">
      <form @submit.stop.prevent="handleSubmit(submit)">

        <!-- BEGIN: Row -->
        <div class="row">
          <!-- BEGIN: Column -->
          <div class="col">
            <!-- BEGIN: Form Group -->
            <div class="form-group">
              <!-- BEGIN: Validation -->
              <validation-provider
                rules="required:true|validUrl"
                v-slot="validationContext"
                name="Domain"
                vid="domain"
              >
                <!-- BEGIN: Label -->
                <label for="domain" class="d-block label-custom">Domain</label>
                <!-- END: Label -->
                <!-- BEGIN: Input -->
                <input
                  id="domain"
                  name="domain"
                  v-model="domain"
                  :state="getValidationState(validationContext)"
                  :class="{
                    'is-invalid': validationContext.errors[0],
                    'is-valid': validationContext.dirty && validationContext.valid,
                  }"
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="https://agentimage.com/"
                >
                <!-- END: Input -->

                <!-- BEGIN: Error Message -->
                <div class="invalid-feedback">
                  {{ validationContext.errors[0] }}
                </div>
                <!-- END: Error Message -->
                <small class="form-text" style="color: #ff8888;">IMPORTANT : With HTTP Protocol and without "www"</small>
              </validation-provider>
              <!-- END: Validation -->
            </div>
            <!-- END: Form Group -->
          </div>
          <!-- END: Column -->
        </div>
        <!-- END: Row -->

        <!-- BEGIN: Row -->
        <div class="row mb-5">
          <!-- BEGIN: Column -->
          <div class="col">
            <h3>Purge Cache</h3>
            <p>Clear cached files to force Cloudflare to fetch a fresh version of those files from your web server.</p>
            <p><strong>Note:</strong> This may temporarily degrade performance for your website and increase load on your origin.</p>
            <!-- BEGIN: Button -->
            <button type="button" class="btn btn-lg btn-primary" :disabled="spinner || dirty === false || valid === false" @click.stop.prevent="purge">
              <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-show="spinner"></span>
              Purge Cache
            </button>
            <!-- END: Button -->
          </div>
          <!-- END: Column -->
        </div>
        <!-- END: Row -->

        <!-- BEGIN: Row -->
        <div class="row">
          <!-- BEGIN: Column -->
          <div class="col">
            <h3>Development Mode(3 hours)</h3>
            <p>Temporarily bypass our cache allowing you to see changes to your origin server in realtime.
            <p><strong>Note:</strong> Enabling this feature can significantly increase origin server load. Development mode does not purge the cache so files will need to be purged after development mode expires.</p>
            <!-- BEGIN: Button -->
            <button type="button" class="btn btn-lg btn-primary" :disabled="spinner || dirty === false || valid === false" @click.stop.prevent="purge(this, 1)">
              <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-show="spinner"></span>
              Turn On
            </button>
            <!-- END: Button -->
          </div>
          <!-- END: Column -->
        </div>
        <!-- END: Row -->
      </form>
    </validation-observer>
    <!-- END: Form -->
  </div>
</template>

<script>
  import global from '@/mixins/global'

  export default {
    mixins: [global],

    data() {
      return {
        domain: '',
        spinner: false,
      }
    },

    methods: {
      submit() {},
      async purge(event, mode = 0)
      {
        this.spinner = true
        console.log(mode)

        try {
          // eslint-disable-next-line no-unused-vars
          const {data} = await this.$http.post(`${process.env.VUE_APP_API_URL}/cloudflare/cache`, {
            domain: this.domain,
            development: mode,
          })

          this.$notify({
            type: 'success',
            text: (mode ? 'Development mode is turn on for ' : 'Purge cache done for ') + this.urlBeautifier(this.domain),
          })

          this.spinner = false
        } catch (e) {
          this.$notify({
            type: 'error',
            text: 'There is an issue sending data to the domain either the domain doesn\'t exist or Initial Setup is not activated.',
          })

          this.spinner = false
        }
      }
    },
  }
</script>
